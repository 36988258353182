import React from 'react';
import { Card, Row, Col, Form, Input, InputNumber, Button, Space } from 'antd';
import { Redirect, useParams } from 'react-router-dom'
import axios from '../../services/axios'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};


const Forms = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false)
  const [editloading, setEditLoading] = React.useState(false)
  const [redirect, setRedirect] = React.useState(false)

  let { id }: any = useParams()
  React.useEffect(() => {
    if (id) {
      getSingleTaux(id)
    }

  }, [])

  const getSingleTaux = (id: number) => {
    axios.get(`/taux-jours/show/${id}`)
      .then(response => {
        
        form.setFieldsValue(response.data)
        setLoading(false)
      })
      .catch(error => {
        console.log('error not found', error);
      });
  }
  const updateTaux = (data: any[], id: number) => {
    setEditLoading(true);
    axios.post(`/taux-jours/edit/${id}`, data)
      .then(function (response) {
        
        setRedirect(true)
        setEditLoading(false);
        form.resetFields();
      })
      .catch(function (error) {
        console.log(error);
        setEditLoading(false);
      });
  }
  const onFinish = (values: any) => {
    
    setLoading(true);

    if (id) {
      updateTaux(values, id);
    }
    else {
      axios.post('/taux-jours/new', values)
      .then(function (response) {
        
        setRedirect(true)
        setLoading(false);
        form.resetFields();
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });

      form.resetFields();
     };
    
      form.resetFields();
    }
    const onReset = () => {
    
  }

  return <>
    <Row>
      <Col span={12} offset={6}>
        <Card size="small" title="Ajouter un nouvel Taux du jour (1 devise ==> GNF )"  >
          <Form {...layout}
            form={form}
            name="nest-messages"
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Form.Item name='taux_USD' label="USD" rules={[{ required: true, type:"number", min: 0, }]}>
              <InputNumber decimalSeparator=',' style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name='taux_EURO' label="EURO" rules={[{ required: true, type:"number", min: 0, }]}>
              <InputNumber decimalSeparator=',' style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name='taux_FCFA' label="FCFA" rules={[{ required: true, type:"number", min: 0, }]}>
              <InputNumber decimalSeparator=',' style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name='description' label="Description">
              <Input.TextArea />
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Space>
                {
                  !id ? (
                    <Button loading={loading} type="primary" htmlType="submit">
                      Enregistrer
                    </Button>
                  ) : (
                    <Button loading={editloading} type="primary" htmlType="submit">
                      Modifier
                    </Button>
                  )
                }
                <Button htmlType="button" onClick={onReset}>
                  Annuler
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
    {
      redirect ? (<Redirect to="/taux-jours/" />) : ''
    }
  </>;
}

export default Forms;