import React from 'react';
import { Button, Modal, Form, Input, InputNumber, Card, DatePicker, Col, Row, Space, Alert } from 'antd';
import { useParams, Redirect } from 'react-router-dom'
import SelectSearchInput from '../../utils/components/selectSearchInput';
import axios from '../../services/axios'
import moment from 'moment'

export default function UpdateOperation() {
    const [form] = Form.useForm();
    const { id }: any = useParams()
    const [clients, setClients] = React.useState([])
    const [devises, setDevises] = React.useState([])
    const [operation, setOperation] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [redirect, setRedirect] = React.useState(false)
    React.useEffect(() => {
        if (id) {
            getSingleOperation(id)
        }
        getClient()
        getDevise()
    }, [])

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };

    const getDevise = () => {
        axios.get('/devises')
            .then(response => {
                
                setDevises(response.data);
                setLoading(false)
            });
    }
    const getClient = () => {
        axios.get('/clients')
            .then(response => {
                
                setClients(response.data);
                setLoading(false)
            });
    }
    const onReset = () => {
        form.resetFields();
    }
    const config = {
        rules: [{ type: 'object' as const, required: true, message: 'Please select time!' }],
    };

    const onChange = (name: string, value: any) => {
        form.setFieldsValue({ [name]: value })
    }
    const getSingleOperation = (id: number) => {
        console.log('date :',);
        axios.get(`/operations/${id}`)
            .then(response => {
                
                setOperation(response.data)
                form.setFieldsValue({ 'date_operation': moment(response.data.date_operation) })
                form.setFieldsValue({ 'numero_recu': response.data.numero_recu })
                form.setFieldsValue({ 'nom_deposant': response.data.nom_deposant })
                form.setFieldsValue({ 'tel_deposant': response.data.tel_deposant })
                form.setFieldsValue({ 'motif': response.data.motif })
                form.setFieldsValue({ 'taux': response.data.taux })
                form.setFieldsValue({ 'montant': response.data.montant })
                form.setFieldsValue({ 'montant_convert': response.data.montant_sortie })
                form.setFieldsValue({ 'type_operation': response.data.type_operation })
                // form.setFieldsValue({ 'devise_sortie': response.data.devise_sortie_id })
                // form.setFieldsValue({ 'client_id': response.data.client_id })
                // form.setFieldsValue(response.data)
            })
            .catch(error => {
                console.log('error not found', error);
            });
    }
    const onSearch = (val: any) => {
        
    }
    const updateDepot = (data: any[], id: number) => {
        axios.post(`/operations/edit/${id}`, data)
            .then(function (response) {
                
                setRedirect(true)
                form.resetFields();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const onFinish = (values: any) => {
        setLoading(true);
        
        updateDepot(values, id)
        setLoading(false);
    };

    const onMontantChange = (value: number) => {
        const isValue = typeof value === 'number'
        if (isValue && form.getFieldValue('devise_id') && form.getFieldValue('devise_sortie') && form.getFieldValue('taux')) {
            // setShowButton(true);
            getAmountConvert()
        }
        else console.log('error')
    }
    const getAmountConvert = () => {
        const data = {
            "devise_id": form.getFieldValue('devise_id'),
            "devise_sortie": form.getFieldValue('devise_sortie'),
            "montant": form.getFieldValue('montant'),
            "taux": form.getFieldValue('taux'),
        }
        axios.post(`operations/devises/convert`, data)
            .then((response) => {
                form.setFieldsValue({ 'montant_convert': response.data })
            }).catch((err) => {
                console.log(err);
            });
    }

    const op = operation as any
    return (
        <>
            <Row>
                <Col span={12} offset={6}>
                    <Card size="small" title={`Modification d'un ${op.type_operation ? op.type_operation : ''}`}  >
                        <Form
                            form={form}
                            name="nest-messages"
                            onFinish={onFinish}
                            validateMessages={validateMessages}
                        >
                            <Form.Item label="Numero reçu" style={{ marginBottom: 0 }} rules={[{ required: true }]}>
                                <Form.Item
                                    name='numero_recu'
                                    style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                                >
                                    <Input
                                        placeholder="N° reçu "
                                    />
                                </Form.Item>
                                <Form.Item name='date_operation' style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}  >
                                    <DatePicker format="YYYY-MM-DD" placeholder="date" style={{ width: '100%' }} />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item name='client_id' label="Clients" rules={[{ required: true }]}>
                                <SelectSearchInput
                                    onChange={(v) => onChange('client', v)}
                                    data={clients}
                                    label='prenom'
                                    label2='nom'
                                    label3='telephone'
                                    valueLabel='id'
                                    onSearch={(value) => onSearch(value)}
                                    placeholder="sectionnez la devise "
                                />
                            </Form.Item>
                            <Form.Item label="Réceveur" style={{ marginBottom: 0 }} rules={[{ required: true }]}>
                                <Form.Item
                                    name='nom_deposant'
                                    style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                                >
                                    <Input placeholder="nom deposant" />

                                </Form.Item>
                                <Form.Item
                                    name='tel_deposant'
                                    style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                                >
                                    <Input placeholder="téléphone deposant" />
                                </Form.Item>
                            </Form.Item>

                            <Form.Item name='motif' label="Modif" rules={[{ required: true }]}>
                                <Input.TextArea placeholder="motif" />
                            </Form.Item>
                            <Form.Item label="Devises *" style={{ marginBottom: 0 }} rules={[{ required: true }]}>
                                <Form.Item
                                    name='devise_id'
                                    rules={[{ required: true }]}
                                    style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                                >
                                    <SelectSearchInput
                                        onChange={(v) => onChange('devise_id', v)}
                                        onSearch={(value) => onSearch(value)}
                                        label='code'
                                        valueLabel='code'
                                        data={devises}
                                        placeholder="Selectionnez d'entrée "
                                    />
                                </Form.Item>
                                <Form.Item
                                    name='devise_sortie'
                                    rules={[{ required: true }]}
                                    style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                                >
                                    <SelectSearchInput
                                        onChange={(v) => onChange('devise_sortie', v)}
                                        onSearch={(value) => onSearch(value)}
                                        label='code'
                                        valueLabel='code'
                                        data={devises}
                                        placeholder="Selectionnez devise de sortie"
                                    />
                                </Form.Item>
                            </Form.Item>

                            <Form.Item initialValue={1} name='taux' label="Le taux" rules={[{ required: true }]}>
                                <InputNumber
                                    placeholder="taux"
                                    style={{ width: '100%' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>

                            <Form.Item name='montant' label="Montant" rules={[{ required: true }]}>
                                <InputNumber
                                    onChange={(value: any) => onMontantChange(value)}
                                    placeholder="Montant"
                                    style={{ width: '100%' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>

                            <Form.Item
                                name='montant_convert'
                                label="M.Converti"
                            >
                                <InputNumber
                                    disabled
                                    placeholder="Montant converti"
                                    style={{ width: '100%' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, ',')}
                                />
                            </Form.Item>


                            <Form.Item initialValue={op.type_operation} name='type_operation' noStyle>
                                <Input type="hidden" />
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 8 }}>
                                <Space>
                                    <Button loading={loading} type="primary" htmlType="submit">
                                        Modifier
                                    </Button>
                                    <Button htmlType="button" onClick={onReset}>
                                        Annuler
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col >
            </Row >
            {
                redirect ? (<Redirect to={`/${op.type_operation}`} />) : ''
            }
        </>
    );
}
