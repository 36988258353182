import * as React from 'react';
import { Table, Card, Space, Form, Row, Popconfirm, Spin, Col, Select, Tag, DatePicker, InputNumber, Button, } from 'antd';
import { Link, Redirect } from 'react-router-dom'
import axios from '../../services/axios'
import SelectSearchInput from '../../utils/components/selectSearchInput';
import moment from 'moment'
import { LoadingOutlined } from '@ant-design/icons';

import DatePickerCustom from '../../utils/components/datePicker';

const { Option } = Select;
const { RangePicker } = DatePicker;
interface IProps {
  pagination: {
    pageSize: Number,
    current: Number
  }
}


const Index = () => {


  const [form] = Form.useForm();
  const [data, setData] = React.useState([]);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 100
  });
  const [loading, setLoading] = React.useState(false);
  const [sum, setSum] = React.useState(0);
  const [sumByDevise, setSumByDevise] = React.useState({});
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const [devises, setDevises] = React.useState([])
  const [dateStart, setDateStart] = React.useState(moment(new Date()).format('YYYY-MM-DD'));
  const [dateEnd, setDateEnd] = React.useState(moment(new Date()).format('YYYY-MM-DD'));

  React.useEffect(() => {
    const dateJour = new Date();
    const dateFormated = moment(dateJour).format('YYYY-MM-DD');
    fetchData({ pagination } as IProps);
    getDevise()
    getSumByDeviseEntree(dateFormated, dateFormated)
  }, [])

  const handleOk = (id: number) => {
    const dateJour = new Date();
    const dateFormated = moment(dateJour).format('YYYY-MM-DD');
    setConfirmLoading(true);
    const datas = {
      "status": 1
    }
    axios.post(`/factures/entre/validate/${id}`, datas)
      .then(function (response) {
        
        setRedirect(true)
        // fetchData({ pagination } as IProps);
        setLoading(true)
        axios.get(`/factures/type/entree/${dateStart}/${dateEnd}`)
          .then(response => {
            // const dataPagination = getRandomuserParams(params)
            
            setData(response.data)
            setSum(getSumData(response.data));
            setLoading(false)
            // setPagination({ ...dataPagination.pagination, total: 20 })
          });
        getSumByDeviseEntree(dateStart, dateEnd)
        setConfirmLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setConfirmLoading(false);
      });
  };

  const filterFacture = (id: number) => {
    var newFactures = data.filter(function (item: any) {
      return (item.id !== id);
    });
    setData(newFactures)
  }

  const handleCancel = () => {
  };


  const renderCustomCell = (item: any, name: string) => {
    if (name == 'client') {
      const { client } = item;
      const clients = client.prenom + ' ' + client.nom + '/' + client.telephone
      return clients;
    }
    else if (name == 'deviseentre') {
      const { deviseentre } = item
      return deviseentre.code
    }
    else if (name == 'devisesortie') {
      const { devisesortie } = item
      return devisesortie.code
    }
  };
  let i = 0;
  const columns = [
    {
      title: "N°",
      key: "index",
      render: (row: any, value: any, index: number = 0) => (
        <>
          {index + 1}
        </>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date_facture',
      key: 'date_facture',
    },
    {
      title: 'N° reçu',
      dataIndex: 'numero_recu',
      key: 'numero_recu'
    },
    {
      title: 'Client',
      key: 'client',
      render: (record: any,) => renderCustomCell(record, 'client'),
    },
    {
      title: 'Déposant',
      key: 'nom_deposant',
      render: (nom_deposant: number, row: any) => (
        <>
          {row.nom_deposant ? (row.nom_deposant + ' ' + row.tel_deposant) : 'Par le client lui méme'}
        </>
      ),
    },
    {
      title: 'Motif',
      dataIndex: 'motif',
      key: 'motif',
    },
    {
      title: 'Code',
      dataIndex: 'code_facture',
      align: 'center',
      key: 'code_facture',
    },
    {
      title: 'Montant',
      dataIndex: 'montant_entre',
      align: 'right',
      key: 'montant_entre',
      render: (montant: any, row: any) => (
        <>
          <Tag >
            {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + row.deviseentre.code}
          </Tag>
        </>
      ),

    },
    {
      title: 'Taux',
      dataIndex: 'taux',
      align: 'center',
      key: 'taux',
      render: (montant: any, row: any) => (
        <>
          <Tag >
            {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          </Tag>
        </>
      ),

    },
    {
      title: 'Montant à Payer',
      dataIndex: 'montant_sortie',
      align: 'right',
      key: 'montant_sortie',
      render: (montant: any, row: any) => (
        <>
          <Tag style={{ fontSize: 15 }} color='success' >
            {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + row.devisesortie.code}
          </Tag>
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: number) => (
        <>
          {
            status == 1 ? (
              <Tag color='success' >
                Payé
              </Tag>
            ) : (
              <Tag color='warning' >
                en attente
              </Tag>
            )
          }
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: number, record: any) => (
        <Space size="middle">
          {
            !record.status ? (
              <Popconfirm
                title="Confirmez vous ce paiement?"
                onConfirm={() => handleOk(id)}
                okText={(<><Spin spinning={confirmLoading} indicator={antIcon} />     OUI </>)}
                cancelText='Non'
                okButtonProps={{ loading: confirmLoading }}
                onCancel={handleCancel}
              >
                <Button type="primary" >  Payer </Button>
              </Popconfirm>

            ) :
              (
                <Button type="default" ><a>Reçu</a></Button>
              )
          }
        </Space>
      ),
    }

  ];

  const getRandomuserParams = (params: any) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });

  const onChange = (name: string, value: any) => {
    form.setFieldsValue({ [name]: value })
  }
  function onSearch(val: string) {
    
  }

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  const getDevise = () => {
    axios.get('/devises')
      .then(response => {
        
        setDevises(response.data);
        setLoading(false)
      });
  }

  const getSumByDeviseEntree = (start: any, end: any) => {
    axios.get(`factures/rapport/sommes-in/by-devise/${start}/${end}`)
      .then(response => {
        
        setSumByDevise(response.data)
        setLoading(false)
      });
  }
  const fetchData = (params: any) => {
    setLoading(true);
    const dateJour = new Date();
    const dateFormated = moment(dateJour).format('YYYY-MM-DD');
    axios.get(`/factures/type/entree/${dateFormated}/${dateFormated}`)
      .then(response => {
        const dataPagination = getRandomuserParams(params)
        
        setData(response.data)
        setSum(getSumData(response.data));
        setLoading(false)
        setPagination({ ...dataPagination.pagination, total: 20 })
      });
  };
  const getSumData = (data: any[]) => {
    let sum = 0;
    data.forEach(element => {
      sum += element.montant_sortie;
    });
    return sum;
  }

  const onDateChange = (dates: any, dateStrings: any) => {
    setDateStart(dateStrings[0])
    setDateEnd(dateStrings[1])

    axios.get(`/factures/type/entree/${dateStrings[0]}/${dateStrings[1]}`)
      .then(response => {
        
        setData(response.data)
        setLoading(false)
      });
    getSumByDeviseEntree(dateStrings[0], dateStrings[1])
  }
  return <>
    <Card size="small" title={<h4 className="text-center">La liste des entrées en attente de paiement</h4>} extra={<Link to="add-entres"></Link>} >
      <Row justify='end'>
        <Form form={form} layout='inline'>
          <Form.Item label="Dates" >
            <DatePickerCustom
              onChange={onDateChange}
            />
          </Form.Item>
          {/* <Form.Item label='Devise'>
            <SelectSearchInput
              onChange={(v) => onChange('devise_entre', v)}
              onSearch={(value) => onSearch(value)}
              data={devises}
              label='code'
              valueLabel='code'
              placeholder="Selectionnez la devise d'entre"
            />
          </Form.Item> */}
        </Form>
      </Row>
      <br />
      <br />
      <Table
        columns={columns as any}
        rowKey={(record: any) => record.login}
        dataSource={data}
        pagination={{ pageSize: 100 }}
        loading={loading}
      // onChange={handleTableChange}
      />

      <Row justify='end'>
        {
          Object.entries(sumByDevise).map(
            (val, index) => <Col span={4}>
              <Form.Item label={`Total en ${val[0]}`} >
                <InputNumber
                  disabled
                  value={val[1] as number}
                  style={{ width: '100%', fontSize: 15 }}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
          )
        }
      </Row>
    </Card>
    {
      redirect ? (<Redirect to="/entrees/valide" />) : ''
    }
  </>;
}

export default Index;