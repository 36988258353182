import React from 'react';
import { Link, Redirect } from 'react-router-dom'
import { Table, Tag, Space, Card, Popconfirm, Spin, Row, Col, Form, DatePicker, Select, InputNumber, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import FormModal from '../components/modalFormInject'
import axios from '../../services/axios'
import DatePickerCustom from '../../utils/components/datePicker';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface IProps {
  pagination: {
    pageSize: Number,
    current: Number
  }
}

const ValidateDepenseInject = () => {
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 100
  });
  const [loading, setLoading] = React.useState(false);
  const [devises, setDevises] = React.useState([])
  const [clients, setClients] = React.useState([])
  const [sumDepenseByDevise, setSumDepenseByDevise] = React.useState({})
  const [sumInjectByDevise, setSumInjectByDevise] = React.useState({})
  const [redirect, setRedirect] = React.useState(false)
  const [dateStart, setDateStart] = React.useState(moment(new Date()).format('YYYY-MM-DD'));
  const [dateEnd, setDateEnd] = React.useState(moment(new Date()).format('YYYY-MM-DD'));

  const [form] = Form.useForm();

  React.useEffect(() => {
    const dateJour = new Date();
    const dateFormated = moment(dateJour).format('YYYY-MM-DD');
    fetchData({ pagination } as IProps);
    getSumDepenseByDevise(dateFormated, dateFormated)
    getSumInjectByDevise(dateFormated, dateFormated)
  }, [])

  const getRandomuserParams = (params: any) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };


  const fetchData = (params: any) => {
    setLoading(true);
    const dateJour = new Date();
    const dateFormated = moment(dateJour).format('YYYY-MM-DD');

    axios.get(`/depenses/depense-inject/${dateFormated}/${dateFormated}`)
      .then(response => {
        // const dataPagination = getRandomuserParams(params)
        
        setData(response.data)
        setLoading(false)
        // setPagination({ ...dataPagination.pagination, total: 20 })
      });
  };

  const getDevise = () => {
    axios.get('/devises')
      .then(response => {
        
        setDevises(response.data);
        setLoading(false)
      });
  }
  const getSumInjectByDevise = (start: any, end: any) => {
    axios.get(`/depenses/rapport/sum-inject/by-devise/${start}/${end}`)
      .then(response => {
        
        setSumInjectByDevise(response.data);
        setLoading(false)
      });
  }
  const getSumDepenseByDevise = (start: any, end: any) => {
    axios.get(`/depenses/rapport/sum-depense/by-devise/${start}/${end}`)
      .then(response => {
        
        setSumDepenseByDevise(response.data);
        setLoading(false)
      });
  }


  const renderCustomCell = (item: any, name: string) => {
    if (name == 'client') {
      const { client } = item;
      const clients = client.prenom + ' ' + client.nom + '/' + client.telephone
      return clients;
    }
    else if (name == 'devise') {
      const { devise } = item
      return devise.code
    }
  };

  const handleCancel = () => {
  };

  const handleOk = (id: number) => {
    const dateJour = new Date();
    const dateFormated = moment(dateJour).format('YYYY-MM-DD');
    setLoading(true);
    axios.post(`/depenses/validate/depense/inject/${id}`)
      .then(function (response) {
        
        axios.get(`/depenses/depense-inject/${dateStart}/${dateEnd}`)
          .then(response => {
            // const dataPagination = getRandomuserParams(params)
            
            setData(response.data)
            getSumDepenseByDevise(dateStart, dateEnd)
            getSumInjectByDevise(dateStart, dateEnd)
            setLoading(false)
            // setPagination({ ...dataPagination.pagination, total: 20 })
          });
        setConfirmLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setConfirmLoading(false);
      });
  };

  let i = 0;
  const columns = [
    {
      title: "N°",
      key: "index",
      render: (row: any, value: any, index: number = 0) => (
        <>
          {index + 1}
        </>
      ),
    },
    {
      title: 'N° reçu',
      dataIndex: 'numero_recu',
      key: 'numero_recu',
    },
    {
      title: 'Date',
      dataIndex: 'date_depense',
      key: 'date_depense',
    },
    {
      title: 'Type operation',
      dataIndex: 'type_operation',
      key: 'type_operation',
    },
    {
      title: 'Montant',
      dataIndex: 'montant',
      key: 'montant',
      render: (montant: any, record: any) => (
        <>
          <Tag >
            {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " " + record.devise.code}
          </Tag>
          { }
        </>
      ),

    },
    {
      title: 'Libelle',
      dataIndex: 'motif',
      key: 'motif',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: number, record: any) => (
        <Space size="middle">
          {
            record.status == 0 ? (
              <Popconfirm
                title="Confirmez vous cette validation?"
                onConfirm={() => handleOk(id)}
                okText={(<><Spin spinning={confirmLoading} indicator={antIcon} />     OUI </>)}
                cancelText='Non'
                okButtonProps={{ loading: confirmLoading }}
                onCancel={handleCancel}
              >
                <Button type="primary" >  valider </Button>
              </Popconfirm>

            ) :
              (
                <Button type="default" ><a>Reçu</a></Button>
              )
          }
        </Space>
      ),
    },

  ];
  const onDateChange = (dates: any, dateStrings: any) => {
    setDateStart(dateStrings[0])
    setDateEnd(dateStrings[1])
    axios.get(`/depenses/depense-inject/${dateStrings[0]}/${dateStrings[1]}`)
      .then(response => {
        
        setData(response.data)
        setLoading(false)
      });
    getSumDepenseByDevise(dateStrings[0], dateStrings[1])
  }
  return <>
    <Card size="small" title="Liste des depenses et injections en attentes de validation ">
      <Row justify='end'>
        <Form layout='inline'>
          <Form.Item label="Dates">
            <DatePickerCustom
              onChange={onDateChange}
            />
          </Form.Item>
        </Form>
      </Row>
      <br />
      <br />

      <Table
        columns={columns}
        rowKey={(record: any) => record.login}
        dataSource={data}
        pagination={{ pageSize: 100 }}
        loading={loading}
      // onChange={handleTableChange}
      />
      <Row justify='end'>
        <Col span={4}>
          somme des injections
        </Col>
        {
          Object.entries(sumInjectByDevise).map(
            (val, index) => <Col span={4}>
              <Form.Item label={`Total en ${val[0]}`} >
                <InputNumber
                  disabled
                  value={val[1] as number}
                  style={{ width: '100%', fontSize: 15 }}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
          )
        }

      </Row>
      <hr />
      <Row justify='end'>
        <Col span={4}>
          somme des depenses
        </Col>

        {
          Object.entries(sumDepenseByDevise).map(
            (val, index) => <Col span={4}>
              <Form.Item label={`Total en ${val[0]}`} >
                <InputNumber
                  disabled
                  value={val[1] as number}
                  style={{ width: '100%', fontSize: 15 }}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
          )
        }

      </Row>
    </Card>
  </>;
}

export default ValidateDepenseInject;