import React from 'react';
import { Card, Row, Col, Form, Input, InputNumber, Button, Space } from 'antd';
import { Redirect, useParams } from 'react-router-dom'
import axios from '../../services/axios'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};


const Forms = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false)
  const [editloading, setEditLoading] = React.useState(false)
  const [redirect, setRedirect] = React.useState(false)
  const { id }: any = useParams();

  React.useEffect(() => {
    if (id) {
      getSingleCustomer(id)
    }

  }, [])

  const getSingleCustomer = (id: number) => {
    axios.get(`/clients/${id}`)
      .then(response => {
        form.setFieldsValue(response.data)
        setLoading(false)
      })
      .catch(error => {
        console.log('error not found', error);
      });
  }

  const updateClient = (data: any[], id: number) => {

    setEditLoading(true);
    axios.post(`/clients/edit/${id}`, data)
      .then(function (response) {
        setRedirect(true)
        setEditLoading(false);
        form.resetFields();
      })
      .catch(function (error) {
        console.log(error);
        setEditLoading(false);
      });
  }

  const onFinish = (values: any) => {
    setLoading(true);
    
    if (id) {
      updateClient(values, id);
    }
    else {
      axios.post('/clients/new', values)
        .then(function (response) {
          
          setRedirect(true)
          setLoading(false);
          form.resetFields();
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
        });
    }
  };
  const onReset = () => {
    form.resetFields();
  }

  return <>
    <Row>
      <Col span={12} offset={6}>
        <Card size="small" title="Ajouter un nouveau client"  >
          <Form {...layout}
            form={form}
            name="nest-messages"
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Form.Item name='nom' label="Nom" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name='prenom' label="Prenom" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name='telephone' label="Téléphone" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name='adresse' label="Adresse">
              <Input.TextArea />
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Space>
                {
                  !id ? (
                    <Button loading={loading} type="primary" htmlType="submit">
                      Enregistrer
                    </Button>
                  ) : (
                    <Button loading={editloading} type="primary" htmlType="submit">
                      Modifier
                    </Button>
                  )
                }
                <Button htmlType="button" onClick={onReset}>
                  Annuler
               </Button>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
    {
      redirect ? (<Redirect to="/clients/" />) : ''
    }
  </>;
}

export default Forms;